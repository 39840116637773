// AppContent.js
import React from 'react';
import Header from '../Header/index';
import { useLocation } from 'react-router-dom';
import Routes from '../../Routes';

const AppContent = () => {
  const location = useLocation();

  const shouldRenderHeader = () => {
    const excludedPaths = ['/', '/signup', '/forgot_password'];
    return !excludedPaths.includes(location.pathname);
  };

  return (
    <>
      <div className="App">
        {shouldRenderHeader() && <Header />}
        <Routes />
      </div>
    </>
  );
};

export default AppContent;