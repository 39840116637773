import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Notification from "../Notification/index";
import anime from "animejs/lib/anime.es.js";
import styles from "./styles.module.css";

import axios from "axios";
import Loader from "../Loader/index";

const REACT_APP_AUTH_URL =
  process.env.REACT_APP_AUTH_URL || "http://localhost:3005";

const LoginForm = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [authError, setAuthError] = useState(false);
  const [authSuccess, setAuthSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    anime({
      targets: ".animated",
      translateY: [-100, 0],
      opacity: [0, 1],
      duration: 1000,
      easing: "easeOutCubic",
    });
  }, []);

  const handleAuthentication = async (e) => {
    e.preventDefault()
    setLoading(true);
    try {
      const response = await axios.post(
        `${REACT_APP_AUTH_URL}/authentication`,
        {
          username,
          password,
          action: "sign_in",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data?.access_token ?? false) {
        setAuthSuccess(true);
        localStorage.setItem("access_token", response.data.access_token);
        setTimeout(() => history.push("/home"), 3000);
      } else {
        setAuthError(true);
      }
    } catch (error) {
      setAuthError(true);
    }
    setLoading(false);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      // Check if Enter key was pressed
      handleAuthentication(); // Call handleAuthentication function
    }
  };

  const closeErrorNotification = () => {
    setAuthError(false);
  };

  const closeSuccessNotification = () => {
    setAuthSuccess(false);
  };

  const handleRegister = async () => {
    try {
      setLoading(true);
      await new Promise((resolve) => {
        setTimeout(() => {
          window.location.href = "/signup";
          resolve();
        }, 1000);
      });
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPass = async () => {
    try {
      setLoading(true);
      await new Promise((resolve) => {
        setTimeout(() => {
          window.location.href = "/forgot_password";
          resolve();
        }, 1000);
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={`LoginForm ${styles.LoginForm}`}>
      <div className={`${styles.LoginForm__body}`}>
        <div
          className={`${styles.container} ${styles.LoginForm__container} z-depth-3 animated fadeIn`}
        >
          <h4 className="center">Conexão Jurídica</h4>
          <form>
            <div className="input-field">
              <input
                id="email"
                type="email"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onKeyDown={handleKeyDown}
                required
              />
              <label htmlFor="email">E-mail</label>
            </div>
            <div className="input-field">
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
                required
              />
              <label htmlFor="password">Senha</label>
            </div>
            <div className="center">
              <button
                type="submit"
                className={`${styles.btn} ${styles.LoginForm__btn} waves-effect waves-light`}
                onClick={handleAuthentication}
              >
                Autenticar
              </button>
            </div>
          </form>
          <div className="center" style={{ marginTop: "15px" }}>
          <button
                type="submit"
                className={`${styles.btn} ${styles.LoginForm__btn} waves-effect waves-light`}
                onClick={handleRegister}
              >
                Registrar
              </button>
              <a>  </a>
              <button
                type="submit"
                className={`${styles.btn} ${styles.LoginForm__btn} waves-effect waves-light`}
                onClick={handleForgotPass}
              >
                Resetar a Senha
              </button>
          </div>
        </div>
        <div
            className={`${styles.animatedBg} ${styles.LoginForm__animatedBg}`}
          ></div>
      </div>
      <Notification
        message="Authentication Failed"
        type="error"
        show={authError}
        onClose={closeErrorNotification}
      />
      <Notification
        message="Authentication Success"
        type="success"
        show={authSuccess}
        onClose={closeSuccessNotification}
      />
      {loading && <Loader />}
    </div>
  );
};

export default LoginForm;
