import React from 'react';
import { Switch, Route } from 'react-router-dom';

import HomePage from './pages/HomePage';
import ListPage from './pages/ListPage';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import QueryCNPJPage from './pages/QueryCNPJPage';
import ListSavedProcessPage from './pages/ListSavedProcessPage';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={SignInPage} />
      <Route exact path="/signup" component={SignUpPage} />
      <Route exact path="/forgot_password" component={ForgotPasswordPage} />
      <Route exact path="/query_cnpj" component={QueryCNPJPage} />
      <Route exact path="/saved_processes" component={ListSavedProcessPage} />
      <Route exact path="/home" component={HomePage} />
      <Route path="/list" component={ListPage} />
    </Switch>
  );
};

export default Routes;
