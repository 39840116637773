import '../App.css';
import SavedLawProcessList from '../components/SavedLawProcessList/index';
import React from 'react';

const ListSavedProcessPage = () => {
  return (
    <div className="App">
        <SavedLawProcessList />
    </div>
  );
}

export default ListSavedProcessPage;
