import React, { useState, useEffect } from 'react';
import './style.css';

import Loader from '../Loader/index';
import Notification from '../Notification/index';
import CustomBadge from '../CustomBadge/index';

import axiosInstance from '../../Api'

const REACT_APP_CNPJ_URL = process.env.REACT_APP_CNPJ_URL || 'http://localhost:3005'
const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3005'

const LawProcessModal = ({ currentBlock, isModalOpen, setIsModalOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState({ type: '', message: '' });
  const [additionalInfo, setAdditionalInfo] = useState(null);
  const [isMovimentosExpanded, setIsMovimentosExpanded] = useState(false);

  useEffect(() => {
    return () => {
      clearLocalStorage();
    };
  }, []);

  const displayNotification = (type, message) => {
    setNotification({ type, message });
    setShowNotification(true);

    setTimeout(() => {
      setShowNotification(false);
    }, 5000);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setAdditionalInfo(null);
    clearLocalStorage();
  };

  const clearLocalStorage = () => {
    localStorage.removeItem('combinedData');
  };

  const handleCaptureCNPJInfo = async () => {
    setAdditionalInfo(null); // Remove previously displayed fields
  
    setIsLoading(true);
    try {
      const requests = currentBlock.polosPassivos.map((passivoLawyer) => {
        const poloPassivoValue = passivoLawyer.nomeParte;
  
        return axiosInstance.post(
          `${REACT_APP_CNPJ_URL}/process-rpa-info/cnpj`,
          {
            company_name: poloPassivoValue.trim(),
            action: 'get_cnpj_contact',
          },
          {
            timeout: 15000, // Add a 15 seconds timeout
          }
        );
      });
  
      const results = await Promise.allSettled(requests);
  
      const successfulResponses = results
        .filter((result) => result.status === 'fulfilled' && result.value.data.statusCode === 200)
        .map((result) => result.value.data.data);
  
      if (successfulResponses.length > 0) {
        displayNotification(
          'success',
          'Matches found for current "Company Name".'
        );
        setAdditionalInfo(successfulResponses);
  
        // Log the new object with process and captured info
        const combinedData = {
          process: currentBlock,
          capturedInfo: successfulResponses,
          identifier: currentBlock.numeroProcessoFormatado
        };
        localStorage.setItem('combinedData', JSON.stringify(combinedData));
        console.log(combinedData);
        
      } else {
        displayNotification('error', 'No matches found for any "Company Name".');
      }
    } catch (error) {
      // Handle other errors
      displayNotification(
        'error',
        'An error occurred while fetching CNPJ information.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveCard = async () => {
    try {
      setIsLoading(true);
      const combinedData = JSON.parse(localStorage.getItem('combinedData'));
      const payload = {
        scope: "processos-rpa",
        data: combinedData
      };
  
      const response = await axiosInstance.post(
        `${REACT_APP_BACKEND_URL}/saveprocess`,
        payload
      );
  
      if (response.status === 200 || response.status === 201) {
        displayNotification('success', 'Processo Salvo');
      } else {
        displayNotification('error', 'Falha ao Salvar o Processo');
      }
    } catch (error) {
      displayNotification('error', 'Falha ao Salvar o Processo');
    }finally{
      setIsLoading(false);
    }
  };

  return (
    isModalOpen && (
      <div className="lawProcessModal-overlay">
        <div className="lawProcessModal">
          <div className="lawProcessModal-content">
            <h4 className="lawProcessModal-title">{currentBlock?.numeroProcessoFormatado}</h4>
            <div className="lawProcessModal-detailsWrapper">
              <p className="lawProcessModal-detailsTitle">Detalhes:</p>
              <p className="lawProcessModal-details">{currentBlock?.nomeClasseCnj}</p>
            </div>
            {currentBlock && (
              <div className="lawProcessModal-currentBlock">
                {Object.entries(currentBlock)
                  .filter(([key]) => !['numeroProcessoFormatado', 'nomeClasseCnj', 'polosAtivos', 'polosPassivos', 'movimentos', 'expanded'].includes(key))
                  .map(([key, value]) => (
                    <p className="lawProcessModal-infoText" key={key}>
                      <span className="lawProcessModal-infoKey">
                        <strong>{key}:</strong>
                      </span>
                      <span className="lawProcessModal-infoValue">{value ? value.toString() : value}</span>
                    </p>
                  ))}
              </div>
            )}

            {additionalInfo && (
              <>
                <hr className="lawProcessModal-separator" />
                <div className="lawProcessModal-additionalInfo">
                  {additionalInfo.map((companyInfo, index) => (
                    <div key={index} className="lawProcessModal-companyInfo">
                      {Object.entries(companyInfo).map(([key, value]) => (
                        <div key={key} className="lawProcessModal-infoPair">
                          <strong className="lawProcessModal-infoKey">{key}:</strong>
                          <span className="lawProcessModal-infoValue">{value}</span>
                        </div>
                      ))}
                      {index < additionalInfo.length - 1 && (
                        <hr className="lawProcessModal-separator" />
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}

            <div className="lawProcessModal-movimentosWrapper">
              <button
                className="btn btn-small lawProcessModal-closeBtn"
                onClick={() => setIsMovimentosExpanded(!isMovimentosExpanded)}
              >
                {isMovimentosExpanded ? 'Hide' : 'Show'} Movimentos
              </button>
              {isMovimentosExpanded && (
                <div className="lawProcessModal-movimentos">
                  <ul className="collection">
                    {currentBlock?.movimentos &&
                      currentBlock.movimentos.map((movimento, index) => {
                        console.log('movimento:', movimento);
                        return (
                          <li key={index} className="collection-item">
                            <div className="lawProcessModal-movimento">
                              <p className="lawProcessModal-movimentoDate">
                                <strong>Data:</strong> {movimento.movement_date}
                              </p>
                              <p className="lawProcessModal-movimentoDesc">
                                <strong>Descrição:</strong> {movimento.movement_description}
                              </p>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>

              )}
            </div>

            <div className="lawProcessModal-lawyersWrapper">
            <p className="lawProcessModal-lawyersTitle">Advogados:</p>
            {[...currentBlock?.polosAtivos, ...currentBlock?.polosPassivos].map((polo, index) => {
              const isJuridical = polo.tipoPessoa === 'J';
              const isPhysical = polo.tipoPessoa === 'F';

              return (
                <div className="lawProcessModal-lawyer" key={index}>
                  <p className="lawProcessModal-lawyerTitle">
                    {isJuridical && (
                      <CustomBadge text="PJ" isBlue={true} />
                    )}
                    {isPhysical && (
                      <CustomBadge text="PF" isBlue={false} />
                    )}
                    {polo.poloProcesso}: {polo.nomeParte}
                  </p>
                  <ul className="lawProcessModal-lawyerItems">
                    {polo.advogados.map((advogado, index) => (
                      <li className="lawProcessModal-lawyerItem" key={index}>
                        {advogado.oab}-{advogado.attorney_name}
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>


            <div className="lawProcessModal-buttons">
              <button
                className="btn btn-small lawProcessModal-closeBtn"
                onClick={handleCaptureCNPJInfo}
                style={{ marginRight: '1rem' }}
              >
                Busca por dados de CNPJ
              </button>
              <button
                className="btn btn-small lawProcessModal-closeBtn"
                onClick={handleSaveCard}
              >
                Salvar Processo
              </button>
            </div>
          </div>
          <button
            className="btn btn-small lawProcessModal-closeBtn"
            onClick={handleCloseModal}
          >
            Close
          </button>
        </div>

        {isLoading && <Loader />}

        <Notification
          message={notification.message}
          type={notification.type}
          show={showNotification}
          onClose={() => setNotification({ ...notification, show: false })}
        />
      </div>
    )
  );
};

export default LawProcessModal;