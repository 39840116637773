import '../App.css';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SignUpForms from '../components/SignUpForms/index';

const SignUpPage = () => {
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      history.push('/home');
    }
  }, [history]);

  return (
    <div className="App">
      <SignUpForms />
    </div>
  );
}

export default SignUpPage;
