import React, { useState, useEffect } from 'react';
import axiosInstance from '../../Api'

import ClearFiltersButton from '../ClearFilterButton/index';

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3005'

function LawProcessFilter({ setFilter }) {
    const [resetFlag, setResetFlag] = useState(false);
    const [cidades, setCidades] = useState([]);
    const [varas, setVaras] = useState([]);
    const [passiveSlots, setPassiveSlots] = useState([]);
    const [captureDates, setCaptureDates] = useState([]);
    const [pageDates, setPageDates] = useState([]);

    useEffect(() => {
        axiosInstance.get(`${REACT_APP_BACKEND_URL}/lawprocess/jurisdiction_city`)
            .then(response => setCidades(response.data))
            .catch(error => console.error(error));
    }, []);

    useEffect(() => {
        axiosInstance.get(`${REACT_APP_BACKEND_URL}/lawprocess/court_name`)
            .then(response => setVaras(response.data))
            .catch(error => console.error(error));
    }, []);

    useEffect(() => {
        axiosInstance.get(`${REACT_APP_BACKEND_URL}/lawprocess/clean_polo_passive`)
            .then(response => setPassiveSlots(response.data))
            .catch(error => console.error(error));
    }, []);

    useEffect(() => {
        axiosInstance.get(`${REACT_APP_BACKEND_URL}/lawprocess/date`)
            .then(response => setCaptureDates(response.data))
            .catch(error => console.error(error));
    }, []);

    useEffect(() => {
        axiosInstance.get(`${REACT_APP_BACKEND_URL}/lawprocess/next_hearing_date`)
            .then(response => setPageDates(response.data))
            .catch(error => console.error(error));
    }, []);

    const handleClearFilters = () => {
        setFilter('process_number', '');
        setFilter('court_abbreviation', '');
        setFilter('jurisdiction_city', '');
        setFilter('court_name', '');
        setFilter('clean_polo_passive', '');
        setFilter('next_hearing_date', '');
        setFilter('last_movement_date', '');
        setFilter('date', '');
      
        setResetFlag((prevFlag) => !prevFlag); // Toggle the resetFlag
      };

    return (
        <div className="row">
            <div className="input-field col s6">
                <input key={resetFlag} id="process_number" name="process_number" type="text" className="validate" onChange={(event) => setFilter('process_number', event.target.value)} />
                <label htmlFor="title">Title</label>
            </div>
            <div className="input-field col s6">
                <input key={resetFlag} id="court_abbreviation" name="court_abbreviation" type="number" className="validate" onChange={(event) => setFilter('court_abbreviation', event.target.value)} />
                <label htmlFor="court_abbreviation">Sigla Tribunal</label>
            </div>
            <div className="input-field col s6">
                <label htmlFor="cidade"></label>
                <select
                    key={resetFlag}
                    id="jurisdiction_city"
                    name="jurisdiction_city"
                    className="browser-default"
                    onChange={(event) => setFilter('jurisdiction_city', event.target.value)}
                >
                    <option value="" disabled selected>
                        Escolha a Cidade
                    </option>
                    {cidades.map((cidade) => (
                        <option key={cidade} value={cidade}>
                            {cidade}
                        </option>
                    ))}
                </select>
            </div>
            <div className="input-field col s6">
                <label htmlFor="vara"></label>
                <select
                    key={resetFlag}
                    id="court_name"
                    name="court_name"
                    className="browser-default"
                    onChange={(event) => setFilter('court_name', event.target.value)}
                >
                    <option value="" disabled selected>
                        Escolha a Vara
                    </option>
                    {varas.map((vara) => (
                        <option key={vara} value={vara}>
                            {vara}
                        </option>
                    ))}
                </select>
            </div>
            <div className="input-field col s6">
                <select
                    key={resetFlag}
                    id="clean_polo_passive"
                    name="clean_polo_passive"
                    className="browser-default"
                    defaultValue=""
                    onChange={(event) =>
                        setFilter('clean_polo_passive', event.target.value)
                    }
                >
                    <option value="" disabled selected>
                        Casos Sem Advogados?
                    </option>
                    {passiveSlots.map((passiveSlot) => (
                        <option key={passiveSlot} value={passiveSlot}>
                            {passiveSlot == true ? "Sim" : "Não"}
                        </option>
                    ))}
                </select>
            </div>
            <div className="input-field col s6">
                <input
                    key={resetFlag}
                    id="next_hearing_date"
                    name="next_hearing_date"
                    type="date"
                    className="validate"
                    onChange={(event) =>
                        setFilter('next_hearing_date', event.target.value)
                    }
                />
                <label htmlFor="next_hearing_date">Next Audience Date</label>
            </div>
            <div className="input-field col s6">
                <select
                    key={resetFlag}
                    id="date"
                    name="date"
                    className="browser-default"
                    defaultValue=""
                    onChange={(event) => setFilter('date', event.target.value)}
                >
                    <option value="" disabled selected>
                        Escolha por data do processo
                    </option>
                    {captureDates.map((date) => (
                        <option key={date} value={date}>
                            {date}
                        </option>
                    ))}
                </select>
            </div>
            <div className="input-field col s6">
                <select
                    key={resetFlag}
                    id="last_movement_date"
                    name="last_movement_date"
                    className="browser-default"
                    defaultValue=""
                    onChange={(event) => setFilter('last_movement_date', event.target.value)}
                >
                    <option value="" disabled selected>
                        Escolha por data de ultimo movimento
                    </option>
                    {pageDates.map((date) => (
                        <option key={date} value={date}>
                            {date}
                        </option>
                    ))}
                </select>
            </div>

            <div className="input-field col s12 center-align">
                <ClearFiltersButton onClick={handleClearFilters} />
            </div>
        </div>
    )
}

export default LawProcessFilter;