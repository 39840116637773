import React from 'react';
import './style.css'

const Loader = () => {
  return (
    <div className="loading-overlay">
      <div className="loading">
        <div className="loading-dot"></div>
        <div className="loading-dot"></div>
        <div className="loading-dot"></div>
        <div className="loading-dot"></div>
      </div>
      <p className="loading-text">Loading...</p>
    </div>
  );
};

export default Loader;