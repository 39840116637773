import '../App.css';
import LawProcessList from '../components/LawProcessList/index';
import React from 'react';

const HomePage = () => {
  return (
    <div className="App">
        <LawProcessList />
    </div>
  );
}

export default HomePage;
