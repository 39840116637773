import React, { useState } from 'react';
import './style.css';

function SortToggle({ isDescending, handleSortToggle }) {
  const [isDragging, setIsDragging] = useState(false);
  const [sliderPosition, setSliderPosition] = useState(isDescending ? 'right' : 'left');
  
  const handleMouseDown = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };
  
  const handleMouseUp = (event) => {
    event.preventDefault();
    setIsDragging(false);
    handleSortToggle();
    setSliderPosition(isDescending ? 'left' : 'right');
  };
  
  const handleMouseMove = (event) => {
    event.preventDefault();
    if (isDragging) {
      const slider = document.querySelector('.slider');
      const sliderWidth = slider.offsetWidth;
      const sliderPosition = event.clientX - sliderWidth / 2;
      if (sliderPosition < 0) {
        setSliderPosition('left');
      } else if (sliderPosition > sliderWidth) {
        setSliderPosition('right');
      } else {
        setSliderPosition(event.clientX < sliderWidth / 2 ? 'left' : 'right');
      }
    }
  };
  
  return (
    <div className="sort-toggle-container">
      <span className="sort-label">Sort by Next Audience Date</span>
      <div 
        className={`slider ${sliderPosition}`}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <div className="slider-knob">
          <span>{isDescending ? '▼' : '▲'}</span>
        </div>
      </div>
    </div>
  );
}

export default SortToggle;
