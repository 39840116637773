import React from 'react';
import styles from './style.module.css';

const links = [
  { title: 'Pagina Inicial', url: '/', iconClass: 'fa-home' },
  { title: 'Processos', url: '/list', iconClass: 'fa-comments' },
  { title: 'Consulta CNPJ', url: '/query_cnpj', iconClass: 'fa-pencil' },
  { title: 'Processos Salvos', url: '/saved_processes', iconClass: 'fa-inbox' },
];

const LinksList = () => {
  return (
    <div className={styles.box}>
      {links.map((link, index) => (
        <div key={link.url} className={styles.selectContainer}>
          <a href={link.url} className={`${styles.select} ${styles[link.iconClass]}`}>
            {link.title}
          </a>
        </div>
      ))}
    </div>
  );
};

export default LinksList;
