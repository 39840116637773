import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import styles from './styles.module.css';
import Loader from '../Loader/index';
import anime from 'animejs/lib/anime.es.js';

const links = [
  { title: 'Pagina Inicial', url: '/home' },
  { title: 'Consulta CNPJ', url: '/query_cnpj' },
  { title: 'Processos', url: '/list' },
  { title: 'Processos Salvos', url: '/saved_processes' },
];

const Header = () => {
  const [loading, setLoading] = useState(false);
  const [givenName, setGivenName] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const name = validateToken();
    if (name) {
      setGivenName(name);
    }
  }, []);

  useEffect(() => {
    anime({
      targets: `.${styles.law_svg}`,
      rotate: '1turn',
      scale: [{ value: 1.1, duration: 2500 }, { value: 1, duration: 2500 }],
      easing: 'linear',
      loop: true,
    });
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    try {
      setLoading(true);
      await new Promise((resolve) => {
        setTimeout(() => {
          localStorage.removeItem('access_token');
          window.location.href = '/';
          resolve();
        }, 1000);
      });
    } finally {
      setLoading(false);
    }
  };

  const validateToken = () => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      window.location.href = '/';
      return null;
    }

    try {
      const decodedToken = jwtDecode(token);

      if (decodedToken.exp * 1000 < Date.now()) {
        localStorage.removeItem('access_token');
        window.location.href = '/';
        return null;
      }

      return decodedToken.given_name;
    } catch (error) {
      localStorage.removeItem('access_token');
      window.location.href = '/';
      return null;
    }
  };

  return (
    <>
      <header className={styles.header}>
        <nav className={styles.nav}>
          {givenName && (
            <div className={styles.authenticated_as}>
              Autenticado como: {givenName}
            </div>
          )}
          <svg
            className={styles.law_svg}
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M32 10L32 0M10 32L0 32M54 32L64 32M32 54L32 64"
              stroke="#ffffff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle cx="32" cy="32" r="30" stroke="#ffffff" strokeWidth="4" />
            <path
              d="M16 20L16 48M48 20L48 48M24 32L40 32"
              stroke="#ffffff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <button className={styles.breadcrumb_button} onClick={toggleMenu}>
          &#9776;
          </button>
          <ul className={`${styles.nav_list} ${isOpen ? styles.open : ''}`}>
            {links.map((link) => (
              <li key={link.url}>
                <a href={link.url} className={styles.nav_item}>
                  {link.title}
                </a>
              </li>
            ))}
            <li>
              <a href="#" className={styles.logout_btn} onClick={handleLogout}>
                Deslogar
              </a>
            </li>
          </ul>
        </nav>
        {loading && <Loader />}
      </header>
    </>
  );
};

export default Header;
