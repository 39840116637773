import React from 'react';

const CustomBadge = ({ text, isBlue }) => {
  const gradientId = isBlue ? 'blue-gradient' : 'red-gradient';
  return (
    <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <radialGradient id="blue-gradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
          <stop offset="0%" style={{ stopColor: '#2196F3', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#0D47A1', stopOpacity: 1 }} />
        </radialGradient>
        <radialGradient id="red-gradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
          <stop offset="0%" style={{ stopColor: '#ff5252', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#d50000', stopOpacity: 1 }} />
        </radialGradient>
      </defs>
      <circle
        className="pj-badge"
        cx="25"
        cy="25"
        r="22"
        fill={`url(#${gradientId})`}
        stroke={isBlue ? '#2196F3' : '#ff5252'}
        strokeWidth="2"
      />
      <text className="pj-text" x="14" y="32">
        {text}
      </text>
      <style>
        {`
          .pj-text {
            font-family: 'Arial', sans-serif;
            font-weight: bold;
            font-size: 15px;
            fill: #ffffff;
          }
        `}
      </style>
    </svg>
  );
};

export default CustomBadge;