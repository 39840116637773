import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

import Loader from '../Loader/index';
import LawSavedProcessModal from '../LawSavedProcessModal/index';
import LawProcessSavedCard from '../LawProcessSavedCard/index';

import axiosInstance from '../../Api';

import styles from './styles.module.css';

// Make sure to bind modal to your app element (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3005';

function SavedLawProcessList() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [expandedMovements, setExpandedMovements] = useState(false);

  const toggleMovements = () => {
    setExpandedMovements(!expandedMovements);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(
          `${REACT_APP_BACKEND_URL}/savedprocess/processos-rpa`
        );
        setData(response.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const openModal = (item) => {
    setSelectedItem(item);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedItem(null);
    setModalIsOpen(false);
  };

  return (
    <div className={styles['card-list']}>
      <LawProcessSavedCard
        blocks={data}
        handleExpand={openModal}
      />
      {selectedItem && (
        <LawSavedProcessModal
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          selectedItem={selectedItem}
          toggleMovements={toggleMovements}
          expandedMovements={expandedMovements}
        />
      )}
      {isLoading && <Loader />}
    </div>
  );
}

export default SavedLawProcessList;

