import React from 'react';
import './style.css';

function LawProcessCounter({ count }) {
  return (
    <div className="block-counter">
      <span className="block-counter__count">{count}</span>
      <span className="block-counter__label">{count === 1 ? ' Result' : ' Results'}</span>
    </div>
  );
}

export default LawProcessCounter;