import React, { useState, useEffect, useRef } from 'react';

import './style.css';

import LawProcessCard from '../LawProcessCard/index';
import LawProcessFilter from '../LawProcessFilter/index';
import LawProcessModal from '../LawProcessModal/index';
import LawProcessCounter from '../LawProcessCounter/index';
import SortToggle from '../SortToggle/index';
import DownloadButton from '../DownloadButton/index';
import PaginationComponent from '../PaginationComponent/index';
import Loader from '../Loader/index';

import axiosInstance from '../../Api'

// import axios from 'axios';
import Papa from 'papaparse';

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3005'

function LawProcessList() {
  const [isLoading, setIsLoading] = useState(false);
  const [blocks, setBlocks] = useState([]);
  const [currentBlock, setCurrentBlock] = useState(null);
  const largestRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    process_number: '',
    court_abbreviation: '',
    jurisdiction_city: '',
    jurisdiction_city: '',
    clean_polo_passive: '',
    next_hearing_date: '',
    last_movement_date: '',
    date: '',
  });
  const [isAscending, setIsAscending] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({
    totalRecords: 0,
    displayedRecords: 0,
    pageNumber: 1,
    totalPages: 1,
    limit: 20,
  });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const order = isAscending ? 'asc' : 'desc';
      const sortQuery = `sort=next_hearing_date&order=${order}`;
      const filterQuery = Object.entries(filters)
        .filter(([_, value]) => value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      try {
        const response = await axiosInstance.get(
          `${REACT_APP_BACKEND_URL}/lawprocess?limit=${pagination.limit}&page=${currentPage}&${sortQuery}&${filterQuery}`
        );
        setBlocks(response.data.data.map(block => ({ ...block, expanded: false })));
        setPagination(response.data.pagination);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [isAscending, currentPage, pagination.limit, filters]);

  useEffect(() => {
    if (largestRef.current) {
      const largestHeight = largestRef.current.clientHeight;
      const cards = document.querySelectorAll('.card');
      cards.forEach(card => card.style.height = `${largestHeight}px`);
    }
  }, [blocks, largestRef]);

  const handleSortToggle = () => {
    setIsAscending(prevState => !prevState);
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleCSVDownload = () => {
    setIsLoading(true);

    // Convert the filtered block data into a CSV string
    const csvData = Papa.unparse(blocks, {
      header: true,
    });

    // Create a temporary link element to trigger the download
    const link = document.createElement('a');
    link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
    link.download = 'filtered-block-data.csv';
    link.click();

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  const handleExpand = (block) => {
    setCurrentBlock(block);
    setIsModalOpen(true);
  };

  const truncateTitle = (title) => {
    const maxLength = 20;
    // return title.length > maxLength ? title.slice(0, maxLength) + '...' : title;
    return title
  }

  const handlePageSizeChange = (newPageSize) => {
    setPagination((prevState) => ({ ...prevState, limit: newPageSize, pageNumber: 1 }));
    setCurrentPage(1); // Reset the current page to 1
  };

  return (
    <div className="container" style={{ maxWidth: '1200px', height: '80vh' }}>
      <DownloadButton handleClick={handleCSVDownload} />
      {isLoading && <Loader />}
      <SortToggle isDescending={!isAscending} handleSortToggle={handleSortToggle} />
      <LawProcessCounter count={pagination.totalRecords} />
      <LawProcessFilter 
        setFilter={(name, value) => setFilters(prevFilters => ({ ...prevFilters, [name]: value }))} 
      />
      <LawProcessCard
        blocks={blocks}
        largestRef={largestRef}
        truncateTitle={truncateTitle}
        handleExpand={handleExpand}
      />

      <LawProcessModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        currentBlock={currentBlock}
      />

    <PaginationComponent
      currentPage={pagination.pageNumber}
      totalPages={pagination.totalPages}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
      currentPageSize={pagination.limit}
    />

    </div>
  );
}
export default LawProcessList;