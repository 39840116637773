import React from 'react';
import PropTypes from 'prop-types';
import './style.css'

const DownloadButton = ({ handleClick }) => {
  return (
    <button
      className="btn"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: '#123a5d',
        color: '#fff',
        borderRadius: '50%',
        width: '60px',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.4)',
        animation: 'pulse 2s infinite',
      }}
      onClick={handleClick}
    >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 30" width="50" height="62.5">
      <g id="download-icon">
        <path d="M12 12l4-4h-3V0h-2v8H8z" fill="#4CAF50"/>
        <path d="M6 14v2h12v-2z" fill="#4CAF50"/>
        <rect x="3" y="16" width="18" height="2" fill="#1B5E20"/>
      </g>
      <g id="excel-icon" transform="translate(0, 8)">
        <rect x="4" y="4" width="16" height="12" fill="#43A047" stroke="#1B5E20" strokeWidth="1"/>
        <path d="M6 6h2v2H6zM8 8h2v2H8zM10 8h2v2h-2zM6 10h2v2H6zM8 10h2v2H8z" fill="#1B5E20"/>
      </g>
    </svg>
    </button>
  );
}

DownloadButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default DownloadButton;