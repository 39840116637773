import React from 'react';
import './style.css'

function ClearFiltersButton({ onClick }) {
  return (
    <div>
      <button
        className="clear-btn-cust"
        type="button"
        onClick={onClick}
      >
        Clear Filters
      </button>
    </div>
  );
}

export default ClearFiltersButton;