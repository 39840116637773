import React, { useState } from 'react';
import axios from 'axios';
import fpStyles from './style.module.css';
import Loader from '../Loader/index';
import Notification from '../Notification/index';
import { useHistory } from 'react-router-dom';

const REACT_APP_AUTH_URL = process.env.REACT_APP_AUTH_URL || 'http://localhost:3005'

const ForgotPasswordForms = () => {
  const [firstFormSubmitted, setFirstFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState({ type: '', message: '' });
  const [email, setEmail] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const history = useHistory();

  const displayNotification = (type, message) => {
    setNotification({ type, message });
    setShowNotification(true);
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${REACT_APP_AUTH_URL}/authentication`,
        {
          username: email,
          action: 'forgot_password',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.message === 'Password reset initiated successfully') {
        setFirstFormSubmitted(true);
        displayNotification('success', 'Password reset initiated. Check your email for the confirmation code.');
      } else {
        displayNotification('error', response.data.message);
      }
    } catch (error) {
      displayNotification('error', error.response.data.message || 'An error occurred');
    }
    setLoading(false);
  };

  const handleConfirmForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${REACT_APP_AUTH_URL}/authentication`,
        {
          username: email,
          confirmation_code: confirmationCode,
          new_password: newPassword,
          action: 'confirm_forgot_password',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.message === 'Password reset successfully') {
        displayNotification('success', 'Password reset successfully! You can now log in.');
        // Redirect user to the desired page after successful password reset
      } else {
        displayNotification('error', response.data.message);
      }
    } catch (error) {
      displayNotification('error', error.response.data.message || 'An error occurred');
    }
    setLoading(false);
  };

  const closeNotification = () => {
    setShowNotification(false);
  };

  return (
    <div className={fpStyles.forgotPasswordCustomContainer}>
      <button
        type="button"
        className={fpStyles.customButtonBack}
        onClick={async () => {
          try {
            setLoading(true);
            await new Promise((resolve) => {
              setTimeout(() => {
                window.location.href = '/';
                resolve();
              }, 1000);
            });
          } finally {
            setLoading(false);
          }
          history.push('/');
        }}
      >
        Back to Home
      </button>

      <div className={fpStyles.forgotPasswordCustomAnimatedDots}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={fpStyles.forgotPasswordCustomFormContainer}>
          <form onSubmit={handleForgotPasswordSubmit}>
            <div className={fpStyles.inputWrapper}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            </div>

            <button
              type="submit"
              className={fpStyles.forgotPasswordCustomSubmitButton}
            >
              Reset Password
            </button>
          </form>
          <div style={{ height: '20px' }} />
          <form onSubmit={handleConfirmForgotPasswordSubmit}>
            <div className={fpStyles.inputWrapper}>
            <label htmlFor="confirmationEmail">Confirmation E-mail</label>
            <input
              type="email"
              id="confirmationEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            </div>
            <div className={fpStyles.inputWrapper}>
            <label htmlFor="confirmation_code">Confirmation Code</label>
            <input
              type="text"
              id="confirmation_code"
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
            />
            </div>
            <div className={fpStyles.inputWrapper}>
            <label htmlFor="new_password">New Password</label>
            <input
              type="password"
              id="new_password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            </div>
            <button
              type="submit"
              className={fpStyles.forgotPasswordCustomSubmitButton}
            >
              Confirm Reset Password
            </button>
          </form>
      </div>
      <Notification
        message={notification.message}
        type={notification.type}
        show={showNotification}
        onClose={closeNotification}
      />
      {loading && <Loader />}
    </div>
  );
};

export default ForgotPasswordForms;
