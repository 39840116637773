import React from 'react';
import './style.css'

const LawProcessCard = ({ blocks, largestRef, truncateTitle, handleExpand }) => {
  return (
    <div className="lawProcessCard-row">
      {blocks.map((block, index) => (
        <div className="lawProcessCard-col" key={block.tribunalSigla}>
          <div className="lawProcessCard-card" ref={block.tribunalSigla === 1 ? largestRef : null}>
            <div className="lawProcessCard-content">
              <div className="lawProcessCard-titleWrapper">
                <span className="lawProcessCard-title">{truncateTitle(block.numeroProcessoFormatado)}</span>
                <button className="lawProcessCard-expandBtn" onClick={() => handleExpand(block)}>Details</button>
              </div>
              <div className="lawProcessCard-infoWrapper">
                <p className="lawProcessCard-infoText">Title: {block.numeroProcessoFormatado}</p>
                <p className="lawProcessCard-infoText">ID: {block.tribunalSigla}</p>
                <p className="lawProcessCard-infoText">City: {block.cidadeJurisdicao}</p>
                <p className="lawProcessCard-infoText">Passive: {block.poloPassivoLimpo}</p>
                <p className="lawProcessCard-infoText">NextAud: {block.dataProximaAudiencia}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LawProcessCard;
