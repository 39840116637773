import React, { useState } from 'react';
import axios from 'axios';
import styles from './style.module.css';
import Loader from '../Loader/index';
import Notification from '../Notification/index';
import { useHistory } from 'react-router-dom';

const REACT_APP_AUTH_URL = process.env.REACT_APP_AUTH_URL || 'http://localhost:3005'

const SignUpForms = () => {
  const [firstFormSubmitted, setFirstFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState({ type: '', message: '' });
  const [firstFormData, setFirstFormData] = useState({
    username: '',
    password: '',
    phone_number: '',
    given_name: '',
  });
  const [confirmationCode, setConfirmationCode] = useState('');
  const [confirmationEmail, setConfirmationEmail] = useState('');

  const history = useHistory();

  const displayNotification = (type, message) => {
    setNotification({ type, message });
    setShowNotification(true);
  };

  const handleFirstFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${REACT_APP_AUTH_URL}/authentication`,
        {
          ...firstFormData,
          action: 'sign_up',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.message === 'User registered successfully') {
        setFirstFormSubmitted(true);
        displayNotification('success', 'Registered with success! Proceed with the Confirmation Code That You Received On Your Email');
      } else {
        let errMessage = response.data.body.includes("phone") ? "Invalid phoneNumber format. The accepted format is +11000000000" : response.data.body
        displayNotification('error', errMessage);
      }
    } catch (error) {
      displayNotification('error', error.response.data.body || 'An error occurred');
    }
    setLoading(false);
  };

  const closeNotification = () => {
    setShowNotification(false);
  };

  const handleSecondFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${REACT_APP_AUTH_URL}/authentication`,
        {
          username: confirmationEmail,
          confirmation_code: confirmationCode,
          action: 'verify_user',
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.message === 'User verified successfully') {
        displayNotification('success', 'Account verified! You can now log in.');
        // Redirect user to the desired page after successful verification
      }else{
        displayNotification('error', response.data.body);
      }
    } catch (error) {
      displayNotification('error', error.response.data.body || 'An error occurred');
    }
    setLoading(false);
  };

  return (
    <div className={styles.signUpCustomContainer}>
      <button
        type="button"
        className={styles.customButtonBack}
        onClick={async () => {
          try {
            setLoading(true);
            await new Promise((resolve) => {
              setTimeout(() => {
                window.location.href = '/';
                resolve();
              }, 1000);
            });
          } finally {
            setLoading(false);
          }
          history.push('/');
        }}
      >
        Back to Home
      </button>

      <div className={styles.signUpCustomAnimatedDots}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={styles.signUpCustomFormContainer}>
          <form onSubmit={handleFirstFormSubmit}>
            <div className={styles.inputWrapper}>
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              value={firstFormData.username}
              onChange={(e) =>
                setFirstFormData({ ...firstFormData, username: e.target.value })
              }
            />
            </div>

            <div className={styles.inputWrapper}>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={firstFormData.password}
              onChange={(e) =>
                setFirstFormData({ ...firstFormData, password: e.target.value })
              }
            />
            </div>

            <div className={styles.inputWrapper}>
            <label htmlFor="phone_number">Phone Number</label>
            <input
              type="text"
              id="phone_number"
              value={firstFormData.phone_number}
              onChange={(e) =>
                setFirstFormData({
                  ...firstFormData,
                  phone_number: e.target.value,
                })
              }
            />
            </div>

            <div className={styles.inputWrapper}>
            <label htmlFor="given_name">Given Name</label>
            <input
              type="text"
              id="given_name"
              value={firstFormData.given_name}
              onChange={(e) =>
                setFirstFormData({ ...firstFormData, given_name: e.target.value })
              }
            />
            </div>


            <button
              type="submit"
              className={styles.signUpCustomSubmitButton}
            >
              Register
            </button>
          </form>
          <div style={{ height: '20px' }} />
          <form onSubmit={handleSecondFormSubmit}>
            <div className={styles.inputWrapper}>
            <label htmlFor="secondEmail">Confirmation E-mail</label>
            <input
              type="text"
              id="secondEmail"
              value={confirmationEmail}
              onChange={(e) => setConfirmationEmail(e.target.value)}
            />
            </div>
            <div className={styles.inputWrapper}>
            <label htmlFor="confirmation_code">Confirmation Code</label>
            <input
              type="text"
              id="confirmation_code"
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
            />
            </div>
            <button
              type="submit"
              className={styles.signUpCustomSubmitButton}
            >
              Verify
            </button>
          </form>
      </div>
      <Notification
        message={notification.message}
        type={notification.type}
        show={showNotification}
        onClose={closeNotification}
      />
      {loading && <Loader />}
    </div>
  );
};

export default SignUpForms;