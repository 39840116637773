import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import styles from "./styles.module.css";
import anime from 'animejs/lib/anime.es.js';

function LawSavedProcessModal({
  modalIsOpen,
  closeModal,
  selectedItem,
  toggleMovements,
  expandedMovements,
}) {
  useEffect(() => {
    if (modalIsOpen) {
      anime({
        targets: `.${styles["modal-content"]}`,
        opacity: [0, 1],
        translateY: ['-50px', '0'],
        easing: 'easeOutQuad',
        duration: 1000,
        delay: 500,
        autoplay: true,
      });
    }
  }, [modalIsOpen]);

  const renderInfoRow = (label, value) => (
    <tr>
      <td className={styles["info-label"]}>{label}:</td>
      <td className={styles["info-value"]}>{value}</td>
    </tr>
  );
  
  const renderTable = (data, renderRow) =>
    data.map((item, index) => (
      <div key={index}>
        <table className={styles["info-table"]}>
          <tbody>{renderRow(item)}</tbody>
        </table>
        {index !== data.length - 1 && <hr className={styles["table-separator"]} />}
      </div>
    ));

  const renderMovement = (movement, index) => (
    <div key={index} className={styles["movement"]}>
      {renderInfoRow("Data da Movimentação", movement.movement_date)}
      {renderInfoRow("Descrição da Movimentação", movement.movement_description)}
    </div>
  );

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Card Details"
    >
      <div className={styles["modal-content"]}>
        <h2>Detalhes do Processo: {selectedItem.identifier}</h2>
        <div className={`${styles["info-wrapper"]} ${styles["process-wrapper"]}`}>
          <h3>Processo</h3>
          {renderTable(
            [
              {
                Numero_do_Processo: selectedItem.process.numeroProcessoFormatado,
                Nome_Classe_CNJ: selectedItem.process.nomeClasseCnj,
                Nome_Orgao_Julgador: selectedItem.process.nomeOrgaoJulgador,
                Cidade_Jurisdicao: selectedItem.process.cidadeJurisdicao,
                Cidade_Nome_Orgao_Julgador:
                  selectedItem.process.cidadeNomeOrgaoJulgador,
                Data: selectedItem.process.data,
                Data_Autuacao: selectedItem.process.dataAutuacao,
                Data_Proxima_Audiencia: selectedItem.process.dataProximaAudiencia,
                Data_Ultimo_Movimento: selectedItem.process.dataUltimoMovimento,
                Tribunal_Sigla: selectedItem.process.tribunalSigla,
              },
            ],
            (item) =>
              Object.entries(item).map(([key, value]) =>
                renderInfoRow(key.replace(/_/g, " "), value)
              )
          )}
        </div>
        <div className={styles["info-wrapper"]}>
          <h3>Dados sobre Empresas</h3>
          {renderTable(selectedItem.capturedInfo, (info) =>
            Object.entries(info).map(([key, value]) =>
              renderInfoRow(key, value)
            )
          )}
        </div>
        <div className={styles["movements-wrapper"]}>
          <h3>Movimentações</h3>
          <button
            onClick={toggleMovements}
            className={styles["toggle-movements-button"]}
          >
            {expandedMovements ? "Fechar Todas Movimentações" : "Exibir Todas Movimentações"}
          </button>
          {expandedMovements &&
            selectedItem.process.movimentos.map((movement, index) => (
              <div key={index} className={styles.movement}>
                {renderMovement(movement)}
              </div>
            ))}
        </div>
        <button
          className={`${styles["close-button"]} ${styles["modal-button"]}`}
          onClick={closeModal}
        >
          Fechar
        </button>
      </div>
    </Modal>
  );
}

export default LawSavedProcessModal;