import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppContent from './components/AppContent/index';
import { NotificationProvider, NotificationContext } from './NotificationContext';
import Notification from './components/Notification/index';

function App() {
  return (
    <NotificationProvider>
      <Router>
        <RenderNotification />
        <AppContent />
      </Router>
    </NotificationProvider>
  );
}

const RenderNotification = () => {
  const { notification } = useContext(NotificationContext);

  return (
    <Notification
      message={notification.message}
      type={notification.type}
      show={notification.show}
      onClose={notification.onClose}
    />
  );
};

export default App;
