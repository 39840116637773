import '../App.css';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ForgotPasswordForms from '../components/ForgotPasswordForms/index';

const ForgotPasswordPage = () => {
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      history.push('/home');
    }
  }, [history]);

  return (
    <div className="App">
      <ForgotPasswordForms />
    </div>
  );
}

export default ForgotPasswordPage;
