import '../App.css';
import QueryCnpjComponent from '../components/QueryCnpjComponent/index';
import React from 'react';

const HomePage = () => {
  return (
    <div className="App">
        <QueryCnpjComponent />
    </div>
  );
}

export default HomePage;
