import React, { useEffect } from 'react';
import styles from './style.module.css';

const Notification = ({ message, type, show, onClose }) => {
  useEffect(() => {
    if (show) {
      setTimeout(() => onClose(), 3000);
    }
  }, [show, onClose]);

  return (
    <div className={`${styles.notification} ${styles[type]} ${show ? styles.show : ''}`}>
      {message}
    </div>
  );
};

export default Notification;
