import React from 'react';

const LawProcessSavedCard = ({ blocks, handleExpand }) => {
  const truncateTitle = (title, maxLength = 80) => {
    return title.length > maxLength ? title.slice(0, maxLength) + '...' : title;
  };

  return (
    <div className="lawProcessCard-row">
      {blocks.map((block, index) => (
        <div className="lawProcessCard-col" key={block.process.numeroProcessoFormatado}>
          <div className="lawProcessCard-card">
            <div className="lawProcessCard-content">
              <div className="lawProcessCard-titleWrapper">
                <span className="lawProcessCard-title">{truncateTitle(block.process.numeroProcessoFormatado)}</span>
                <button className="lawProcessCard-expandBtn" onClick={() => handleExpand(block)}>Details</button>
              </div>
              <div className="lawProcessCard-infoWrapper">
                <p className="lawProcessCard-infoText">Title: {block.process.numeroProcessoFormatado}</p>
                <p className="lawProcessCard-infoText">ID: {block.process.tribunalSigla}</p>
                <p className="lawProcessCard-infoText">City: {block.process.cidadeJurisdicao}</p>
                <p className="lawProcessCard-infoText">NextAud: {block.process.dataProximaAudiencia}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LawProcessSavedCard;
