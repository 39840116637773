import axios from 'axios';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const access_token = localStorage.getItem('access_token');
    if (access_token) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }
    config.headers['Content-Type'] = 'application/json'
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('Axios interceptor error:', error); // Log the error for debugging

    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem('access_token');
        window.location.href = '/';
      }
    } else if (error.code === 'ERR_NETWORK') {
      // Handle network errors
      localStorage.removeItem('access_token');
      window.location.href = '/';
    } else if (error.request) {
      // The request was made but no response was received
      console.error('Request error:', error.request);
    } else {
      // Something happened in setting up the request that triggered an error
      console.error('Request setup error:', error.message);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
