import '../App.css';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LoginForm from '../components/LoginForm/index';

const SignInPage = () => {
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      history.push('/home');
    }
  }, [history]);

  return (
    <div className="App">
      <LoginForm />
    </div>
  );
}

export default SignInPage;
