import React, { useState, useEffect, useRef } from "react";
import styles from "./styles.module.css";

import Loader from "../Loader/index";
import Notification from "../Notification/index";
import anime from "animejs/lib/anime.es.js";

import axiosInstance from "../../Api";

const cnpj_fields = [
  "CNPJ",
  "Razão Social",
  "Capital Social",
  "Natureza Jurídica",
  "Empresa MEI",
  "Telefone",
  "E-MAIL",
  "Quadro Societário",
  "Atividade Principal",
  "Atividades Secundárias",
];

const REACT_APP_CNPJ_URL =
  process.env.REACT_APP_CNPJ_URL || "http://localhost:3005";

const QueryCnpjComponent = () => {
  const [inputValue, setInputValue] = useState("");
  const [companyData, setCompanyData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState({ type: "", message: "" });
  const titleRef = useRef(null);

  useEffect(() => {
    anime({
      targets: titleRef.current,
      opacity: [0, 1],
      translateY: ["-50px", "0"],
      easing: "easeOutQuad",
      duration: 1000,
      delay: 500,
      autoplay: true,
    });
  }, []);

  const displayNotification = (type, message) => {
    setNotification({ type, message });
    setShowNotification(true);
  };

  const closeNotification = () => {
    setShowNotification(false);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const response = await axiosInstance.post(
        `${REACT_APP_CNPJ_URL}/process-rpa-info/cnpj`,
        {
          company_name: inputValue,
          action: "get_cnpj_contact",
        }
      );

      if (response.data.statusCode === 200) {
        displayNotification(
          "success",
          'Matches found for current "Company Name".'
        );
        setCompanyData(response.data);
      } else {
        displayNotification("error", response.data.data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  const renderResultRow = (label, value) => (
    <div className={styles.customCnpj_result_row}>
      <span className={styles.customCnpj_label}>{label}:</span>
      <span className={styles.customCnpj_value}>{value}</span>
    </div>
  );

  return (
    <div className={styles.customCnpj_container}>
      <h2 ref={titleRef} className={styles.customCnpj_title}>
        Busca de dados CNPJ
      </h2>
      <div className={styles.customCnpj_input_group}>
        <input
          type="text"
          value={inputValue}
          onKeyPress={handleKeyPress}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Enter company name"
          className={styles.customCnpj_input}
        />
        <button className={styles.customCnpj_button} onClick={handleSubmit}>
          Submit
        </button>
      </div>
      {companyData && (
        <div className={styles.customCnpj_results}>
          {cnpj_fields.map((ref) =>
            renderResultRow(ref, companyData.data[ref])
          )}
        </div>
      )}
      {isLoading && <Loader />}
      <Notification
        message={notification.message}
        type={notification.type}
        show={showNotification}
        onClose={closeNotification}
      />
    </div>
  );
};

export default QueryCnpjComponent;
