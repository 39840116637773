import '../App.css';
import LinksList from '../components/LinkList/index';
import React from 'react';

const HomePage = () => {
  return (
    <div className="bgColor">
        <LinksList />
    </div>
  );
}

export default HomePage;
