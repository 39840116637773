import React, { useState, useEffect } from 'react';
import './style.css';

const PaginationComponent = ({ currentPageSize, currentPage, totalPages, handlePageChange, handlePageSizeChange }) => {
  const [inputValue, setInputValue] = useState(currentPage);

  useEffect(() => {
    setInputValue(currentPage);
  }, [currentPage]);

  const handleInputChange = (e) => {
    const value = parseInt(e.target.value) || '';
    setInputValue(value);
  };

  const handleInputSubmit = (e) => {
    e.preventDefault();
    if (inputValue > 0 && inputValue <= totalPages && inputValue !== currentPage) {
      handlePageChange(inputValue);
    }
  };

  const handlePageSizeSelect = (e) => {
    handlePageSizeChange(parseInt(e.target.value));
  }

  const pageSizes = [20, 40, 60, 80, 100];
  const pageSizeOptions = pageSizes.map((size) => (
    <option key={size} value={size}>
      {size}
    </option>
  ));

  return (
    <div className="pagination">
      <button
        className="pagination-button"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <span className="pagination-info">
        Page {currentPage} of {totalPages}
      </span>
      <form onSubmit={handleInputSubmit}>
        <input
          className="pagination-input"
          type="number"
          min="1"
          max={totalPages}
          value={inputValue}
          onChange={handleInputChange}
        />
      </form>
      <div className="pagination-select-container">
        <span className="pagination-select-label">Results per page: </span>
        <div className="custom-select">
          <select className="pagination-select" value={currentPageSize} onChange={handlePageSizeSelect}>
            {pageSizeOptions}
          </select>
        </div>
      </div>
      <button
        className="pagination-button"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default PaginationComponent;